import imageUrlBuilder from '@sanity/image-url';
import { createClient } from 'next-sanity';

export const client = createClient({
  projectId: 'r3tl6pu7',
  dataset: 'production',
  apiVersion: '2024-01-01',
  useCdn: false,
});

export function getPostImageUrl({
  source,
  projectId,
  dataset,
  width = 550,
  height = 310,
}) {
  return projectId && dataset && source
    ? imageUrlBuilder({ projectId, dataset })
        .image(source)
        .width(width)
        .height(height)
        .url()
    : null;
}
