import { Icon } from '@/components/standalone';
import Collapse from '@/components/standalone/Collapse/Collapse';
import classNames from 'classnames';
import Link from 'next/link';
import { useState } from 'react';
import styles from './FaqSection.module.scss';

const collapseIds = ['collapse-1', 'collapse-2', 'collapse-3', 'collapse-4'];

function FaqSection() {
  const [openedCollapse, setOpenedCollapse] = useState(null);

  return (
    <div className={classNames('section', styles.faqSection)}>
      <div className="max-content-width-small">
        <div className={styles.faqSectionContainer}>
          <div className={styles.faqSectionHeader}>
            <div className="tb-tag">
              <Icon name="CircleHelp" />
              Learn more
            </div>
            <h2>FAQs</h2>
          </div>
          <div className={classNames(styles.faqSectionCollapseContainer)}>
            <Collapse
              isOpen={openedCollapse === collapseIds[0]}
              onClick={() =>
                setOpenedCollapse((stateOpenedCollapse) =>
                  stateOpenedCollapse === collapseIds[0]
                    ? null
                    : collapseIds[0],
                )
              }
              buttonContent={
                <div className={styles.faqSectionCollapseButtonContent}>
                  <h3>What is Trustblock?</h3>
                  <Icon name="Plus" />
                </div>
              }
            >
              <p>
                Trustblock offers a robust infrastructure designed for auditors
                to publish their smart contract audits. We provide neutral and
                insightful metrics for each auditor&apos;s profile, enabling
                projects to confidently identify their next auditor.
                <br />
                Additionally, we extract critical security data from audit
                reports and make it accessible to the public through our
                platform, labels, API, or directly on the blockchain.
              </p>
            </Collapse>
            <Collapse
              isOpen={openedCollapse === collapseIds[1]}
              onClick={() =>
                setOpenedCollapse((stateOpenedCollapse) =>
                  stateOpenedCollapse === collapseIds[1]
                    ? null
                    : collapseIds[1],
                )
              }
              buttonContent={
                <div className={styles.faqSectionCollapseButtonContent}>
                  <h3>Who publishes audits on Trustblock?</h3>
                  <Icon name="Plus" />
                </div>
              }
            >
              <p>
                All audits published on Trustblock are conducted by verified
                auditing firms.
                <br /> While we do not evaluate the quality of
                auditors—recognizing that projects have varying needs and
                require different technical expertise—we focus on ensuring that
                the firms are legitimate and have a verifiable public audit
                history.
              </p>
            </Collapse>
            <Collapse
              isOpen={openedCollapse === collapseIds[2]}
              onClick={() =>
                setOpenedCollapse((stateOpenedCollapse) =>
                  stateOpenedCollapse === collapseIds[2]
                    ? null
                    : collapseIds[2],
                )
              }
              buttonContent={
                <div className={styles.faqSectionCollapseButtonContent}>
                  <h3>Is it decentralized?</h3>
                  <Icon name="Plus" />
                </div>
              }
            >
              <p>
                At Trustblock, our mission is to become the ultimate Web3
                security hub, and we believe this can only be achieved through
                large-scale decentralization. However, given the current
                limitations of decentralized governance and semi-immutable
                processes, we are building on a closely managed, centralized
                system. <br />
                Once our solution matures and is robust enough to support
                decentralization, we plan to transition accordingly.
                <br />
                <br />
                <Link href="https://docs.trustblock.run/decentralization-plan">
                  Read our decentralization plan here
                </Link>
              </p>
            </Collapse>
            <Collapse
              isOpen={openedCollapse === collapseIds[3]}
              onClick={() =>
                setOpenedCollapse((stateOpenedCollapse) =>
                  stateOpenedCollapse === collapseIds[3]
                    ? null
                    : collapseIds[3],
                )
              }
              buttonContent={
                <div className={styles.faqSectionCollapseButtonContent}>
                  <h3>What can I use it for?</h3>
                  <Icon name="Plus" />
                </div>
              }
            >
              <p>
                If you&apos;re an auditor, Trustblock enables you to publish
                your audits, unlocking access to metrics, critical state
                monitoring, and more.
                <br />
                If you&apos;re a protocol or app, you can leverage our labels
                and API to enhance your UI/UX with security data.
                <br />
                If you&apos;re searching for an auditor, our platform helps you
                find the perfect match.
                <br />
                Even if none of these apply, you can still use Trustblock to
                verify whether a protocol has been audited by a trusted auditor.
              </p>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
