import Image from 'next/image';
import classNames from 'classnames';
import mobulaLogo from 'public/images/mobula.png';
import fabricLogo from 'public/images/fabric.png';
import frstLogo from 'public/images/frst.png';
import zokyoLogo from 'public/images/zokyo.png';
import bpiLogo from 'public/images/bpi.png';
import Link from 'next/link';
import styles from './PartnersSection.module.scss';

function PartnersSection() {
  return (
    <div className={classNames('section', styles.partnersSection)}>
      <div className={styles.partnersSectionRow}>
        <Link
          href="https://zokyo.io/"
          target="_blank"
          rel="noreferrer"
          className={classNames(
            styles.partnersSectionRowItem,
            styles.zokyoLogo,
          )}
        >
          <Image src={zokyoLogo} alt="zokyo" quality={100} />
        </Link>
        <Link
          href="https://mobula.fi/"
          target="_blank"
          rel="noreferrer"
          className={classNames(
            styles.partnersSectionRowItem,
            styles.mobulaLogo,
          )}
        >
          <Image src={mobulaLogo} alt="mobula" quality={100} />
        </Link>
        <Link
          href="https://www.fabric.vc/"
          target="_blank"
          rel="noreferrer"
          className={classNames(
            styles.partnersSectionRowItem,
            styles.fabricLogo,
          )}
        >
          <Image src={fabricLogo} alt="fabric" quality={100} />
        </Link>
        <Link
          href="https://www.frst.vc/"
          target="_blank"
          rel="noreferrer"
          className={classNames(styles.partnersSectionRowItem, styles.frstLogo)}
        >
          <Image src={frstLogo} alt="frst" quality={100} />
        </Link>
        <Link
          href="https://www.bpifrance.fr"
          target="_blank"
          rel="noreferrer"
          className={classNames(styles.partnersSectionRowItem, styles.bpiLogo)}
        >
          <Image src={bpiLogo} alt="bpifrance" quality={100} />
        </Link>
      </div>
      <div className={styles.partnersSectionLabel}>
        Trusted by partners and venture capitals across the globe
      </div>
    </div>
  );
}

export default PartnersSection;
