function insertCommas(number) {
  const numberString = number.toString();
  const parts = numberString.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const steps = {
  thousands: 1000,
  millions: 1000000,
  billions: 1000000000,
};

export function formatNumber(number, step = steps.thousands) {
  let finalNumber = number;

  if (finalNumber >= 1000000000) {
    if (step <= steps.billions) {
      finalNumber = (finalNumber / 1000000000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        finalNumber = finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber} billion`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  } else if (finalNumber >= 1000000) {
    if (step <= steps.millions) {
      finalNumber = (finalNumber / 1000000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        finalNumber = finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber} million`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  } else if (finalNumber >= 1000) {
    if (step <= steps.thousands) {
      finalNumber = (finalNumber / 1000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        finalNumber = finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber} thousand`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  }

  return finalNumber?.toString();
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString();
}

export const postTagToIcon = {
  news: 'Bell',
  security: 'ShieldCheck',
  community: 'User',
};
