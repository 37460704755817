import { Collapse as ReactCollapse } from 'react-collapse';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Collapse.module.scss';

function Collapse({
  buttonContent,
  children,
  className,
  isOpen = false,
  onClick,
}) {
  return (
    <div
      className={classNames(styles.collapse, className, {
        [styles.collapseOpened]: isOpen,
      })}
    >
      <button className={styles.collapseButton} type="button" onClick={onClick}>
        {buttonContent}
      </button>
      <ReactCollapse
        theme={{
          collapse: styles.collapseWrapper,
          content: styles.collapseContent,
        }}
        isOpened={isOpen}
      >
        {children}
      </ReactCollapse>
    </div>
  );
}

Collapse.propTypes = {
  buttonContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Collapse;
