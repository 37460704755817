import classNames from 'classnames';
import { SvgAnimation, Icon, Button } from '@/components/standalone';
import { useRef } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import globalPropTypes from '@/components/globalPropTypes';
import styles from './IntroSection.module.scss';

function IntroSection() {
  const labelsRef = useRef();
  const contentRef = useRef();
  const { isOnScreen: isContentOnScreen } = useOnScreen({
    ref: contentRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });
  const { isOnScreen: isLabelsOnScreen } = useOnScreen({
    ref: labelsRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  return (
    <div className={classNames('section', styles.introSection)} id="intro">
      <div ref={labelsRef}>
        <SvgAnimation
          name="Labels"
          autoplay={isLabelsOnScreen}
          className={styles.introSectionAnimation}
          onComplete={(animation) => {
            animation.playSegments(
              [2.05 * 60, animation.getDuration() * 60],
              true,
            );
            // eslint-disable-next-line no-param-reassign
            animation.loop = true;
          }}
        />
      </div>
      <div
        className={classNames({
          [styles.introSectionContentAppear]: isContentOnScreen,
        })}
        ref={contentRef}
      >
        <div className="tb-tag dark">
          <Icon name="Coffee" />
          How it works
        </div>
        <h2>Balancing trust & security</h2>
        <p>
          All audits published by verified firms on Trustblock are stored on
          IPFS, making them transparent, immutable, verifiable & scam-proof.
          <br />
          <br />
          We extract critical data from the reports and make it available to the
          public through our platform & API.
        </p>
        <Button
          className={styles.introSectionButton}
          type="link"
          mode="primary"
          href="https://docs.trustblock.run"
          icon="ArrowRight"
        >
          Check our documentation
        </Button>
      </div>
    </div>
  );
}

IntroSection.propTypes = globalPropTypes;
export default IntroSection;
