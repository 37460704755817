import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';
import styles from './Carousel.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  selectedItem: PropTypes.number,
};

function Carousel({ className, children, selectedItem }) {
  return (
    <ReactResponsiveCarousel
      className={classNames(styles.carousel, className)}
      showArrows={false}
      showStatus={false}
      selectedItem={selectedItem}
      showThumbs={false}
      transitionTime={500}
    >
      {children}
    </ReactResponsiveCarousel>
  );
}

Carousel.propTypes = propTypes;

export default Carousel;
