import { useNextSanityImage } from 'next-sanity-image';
import Image from 'next/image';
import { PortableText } from 'next-sanity';
import { client } from '@/helpers/sanity';
import classNames from 'classnames';
import styles from './CustomImage.module.scss';

const PropTypes = require('prop-types');

const propTypes = {
  sanityImage: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    asset: PropTypes.shape({}),
    caption: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  contained: PropTypes.bool,
  className: PropTypes.string,
};

function CustomImage({ sanityImage, contained, className }) {
  const imageProps = useNextSanityImage(client, sanityImage);

  return (
    <div className={classNames(styles.customImage, className)}>
      <div className={styles.customImageContainer}>
        <Image
          src={imageProps.src}
          alt={sanityImage.alt}
          loader={imageProps.loader}
          quality={100}
          priority
          width={contained ? undefined : imageProps.width}
          height={contained ? undefined : imageProps.height}
          layout={contained ? 'fill' : 'responsive'}
          fill={contained}
          objectFit={contained ? 'cover' : undefined}
          style={{
            objectFit: contained ? 'cover' : 'unset',
          }}
        />
      </div>
      {Array.isArray(sanityImage.caption) && (
        <PortableText value={sanityImage.caption} />
      )}
    </div>
  );
}

CustomImage.propTypes = propTypes;
export default CustomImage;
