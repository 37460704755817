import classNames from 'classnames';
import { useRef } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import quillAuditsLogo from 'public/images/quillaudits.jpg';
import zokyoLogo from 'public/images/zokyo-twitter-logo.jpg';
import hashexLogo from 'public/images/hashex.jpg';
import cyberscopeLogo from 'public/images/cyberscope.jpg';
import hackenLogo from 'public/images/hacken.jpg';
import solidproofLogo from 'public/images/solidproof.jpg';
import { Button, Icon } from '@/components/standalone';
import Image from 'next/image';
import globalPropTypes from '@/components/globalPropTypes';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styles from './NetworkSection.module.scss';

const tweetCards = [
  {
    image: hackenLogo,
    name: 'Hacken',
    username: '@hackenclub',
    url: 'https://twitter.com/hackenclub',
  },
  {
    image: zokyoLogo,
    name: 'Zokyo',
    username: '@zokyo_io',
    url: 'https://twitter.com/zokyo_io',
  },
  {
    image: hashexLogo,
    name: 'Hashex',
    username: '@HashexOfficial',
    url: 'https://twitter.com/HashExOfficial',
  },
  {
    image: cyberscopeLogo,
    name: 'Cyberscope',
    username: '@Cyberscope_io',
    url: 'https://twitter.com/Cyberscope_io',
  },
  {
    image: quillAuditsLogo,
    name: 'QuillAudits',
    username: '@quillaudits_ai',
    url: 'https://twitter.com/quillaudits_ai',
  },
  {
    image: solidproofLogo,
    name: 'SolidProof',
    username: '@SolidProof_io',
    url: 'https://twitter.com/SolidProof_io',
  },
];

function NetworkSection({ liveMetrics }) {
  const headingRef = useRef();
  const { isOnScreen: isHeadingOnScreen } = useOnScreen({
    ref: headingRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  return (
    <div className={classNames('section', styles.networkSection)}>
      <div className="max-content-width-small">
        <div
          className={classNames({
            [styles.networkSectionHeadingAppear]: isHeadingOnScreen,
          })}
          ref={headingRef}
        >
          <div className="tb-tag">
            <Icon name="LogIn" />
            Get onboard
          </div>
          <h2>Join the network</h2>
          <p>Help make web3 a safer place for everyone, join us now!</p>
          <div className={styles.networkSectionContent}>
            <div className={styles.networkSectionTweetCardsWrapper}>
              {tweetCards.map((tweetCard) => (
                <Link
                  className={styles.tweetCard}
                  key={tweetCard.username}
                  href={tweetCard.url}
                  target="_blank"
                >
                  <div>
                    <Image
                      src={tweetCard.image}
                      alt={tweetCard.name}
                      quality={100}
                    />
                    <div>
                      <h3>{tweetCard.name}</h3>
                      <span>{tweetCard.username}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <Button
              type="link"
              mode="outline"
              href="https://app.trustblock.run/?tab=auditors"
              className={styles.networkSectionMore}
            >
              See {liveMetrics.totalAuditors} more...
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

NetworkSection.propTypes = {
  ...globalPropTypes,
  liveMetrics: PropTypes.shape(),
};
export default NetworkSection;
